html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell,
    Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    background-image: url('./Images/purpleBackground.jpg');
    background-size: cover;
    color: 	#D3D3D3
}

* {
  box-sizing: border-box;
}


.projectHolder {
  background-color: red;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}



img {
  height: 80%;
  width: 100%;
  padding-right: 0%;
}

.topnav {
  background-color: #333;
  overflow: hidden;
}

/* Style the links inside the navigation bar */
.navBar a {
  float: left;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

/* Change the color of links on hover */
.navBar a:hover {
  background-color: #ddd;
  color: black;
}

/* Add a color to the active/current link */
.navBar a.active {
  background-color: #04AA6D;
  color: white;
}

h2 {
  margin-top: 5%;
  text-align:center;
  font-size: 200%;
  color: white;
}

.projectBox {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.project {
    flex: 0 1 20%;
    background-color: #515151;
    margin: 2%;
    height: 100%;
    align-items:flex-start
}

.project p {
  color: lightblue;
}

.contactBox {
  margin-top: 2%;
  margin-left: 25%;
  margin-right: 18%;
  display: flex;
  justify-content: space-between;
}

.contactBox p {
  text-align: left;
  color: darkgrey;
  font-size: 200%;
  color: #9b9bb4;
  font-weight: bold;
}

.contactBox p a {
  color: #9b9bb4;
}

.temp {
  margin-left: 25%;
  font-size: 250%;
  margin-top: 10%;
}

.headBar, .navBar *, .navBar a {
  background-color: grey;
  color: black;
}

.navBar a {
  font-weight: bold;
}

.aboutBox {
  display: flex;
}

.aboutBox img {
  width: 20%;
  margin-left: 8%;
  margin-top: 5%;
}

.aboutBox div {
  font-size: 120%;
  width: 42%;
  margin-left: 5%;
  margin-right: 7%;
  margin-top: 2%;
  color: #d3d3d3;
}

.aboutBox div h3 {
  font-size: 125%;
  color: white;
}

.homePage p {
  font-size: 150%;
  margin-left: 5%;
}

.homePage h3 {
  font-size: 150%;
  margin-bottom: 5%;
  margin-left: 5%;
  margin-top: 4%;
}

.backButton img {
  width: 7%;
}

.singleProject {
  margin-top: -5%;
}

.singleProject a {
  color: #add8e6;
  
}

.singleProject p {
  font-size: 125%;
  margin-top: 3%;
  margin-left: 5%;
  margin-right: 5%;
}

.decisionImg {
  width: 60%;
  margin-left: 20%;
  margin-top: 2%;
}

.pentagoImg {
  width: 40%;
  margin-left: 30%;
  margin-top: 2%;
}

.link {
  color: #add8e6;
  text-align: center;
}

.exampleImg {
  width: 20%;
  margin-left: 5%;
}

.important {
  color: red;
}